import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import './FooterPage.css';

const FooterPage = () => {
  const [iPais, setCountry] = useState(null);
  const [iCiudad, setCity] = useState(null);

  useEffect(() => {
    Axios.get(`https://cloud.funnelstitan.com/ingsux/ipquery/reverse`)
      .then((response) => {
        if (response.data.reqip === false) {
          // Manejar el caso de error aquí si es necesario
        } else {
          const Pais = response.data.vContry;
          const Ciudad = response.data.vCity;
          setCountry(Pais);
          setCity(Ciudad);
        }
      })
      .catch((error) => {
        // Manejar errores de la solicitud a la API
        console.error('Err');
      });
  }, []);

  return (
    <div className="iFooter">
      <div className="container">
        <div className="iFooter-column">
          <div className="iFooter-pie">
            <div className='iFooter-pie-l'>
              <span className="text-14-s tbg-withe">&nbsp;Petrosur&nbsp;&copy;&nbsp;2024 &nbsp;&nbsp;{iPais ? <span>{iPais}-{iCiudad}</span> : <span>Localizando...</span>}</span>
            </div>
            <div className='iFooter-pie-r'>
              <span className="text-10-s tbg-gris-c"><a className='link-back-w' href='https://viwhite.com' alt="Viwhite">Viwhite</a> </span>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default FooterPage;
